.sign-container form {
    display: flex;
    flex-direction: column;
    color: goldenrod;
    font-weight: bold;
    margin: 10px 0px 15px;
    width: 350px;
}

.sign-container form h1 {
    margin-bottom: 50px;
}

.sign-container form input {
    border-radius: 5px;
    padding: 15px;
    margin: 10px 0px;
}

.sign-container form .errorInput {
    border: 1px solid red;
}

.sign-container form button {
    margin-top: 25px;
    font-weight: bold;
    height: 45px;
}

.signUp {
    text-decoration: underline;
}

.signUp:hover {
    color: goldenrod;
    cursor: pointer;
}

.error {
    color: red;
    text-align: start;
    margin: 0px 0px 10px;
    font-size: 12px;
}

.noError {
    display: none;
}

.generalError {
    color: red;
    text-align: center;
    margin: 15px 0px;
    font-size: 14px;
}

@media only screen and (max-width: 800px) {
    .sign-container form {
        width: auto;
        max-width: 325px;
    }
}