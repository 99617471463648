.fileInput {
    display: none;
}

.fileInputWrapper {
    display: flex;
    justify-content: center;
}

.fileInputContainer {
    position: relative;
    margin-bottom: 20px;
}

.fileInputLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 250px;
    border-radius: 6px;
    border: 2px dashed goldenrod;
    cursor: pointer;
    padding: 15px;
}

.fileInputLabel:hover {
    opacity: 0.6;
}

.fileInputLabel span {
    text-align: center;
    font-size: 16px;
    color: goldenrod;
}

.fileChosen {
    color: greenyellow;
}

.fileInput[type="file"] {
    display: none;
}

.vidoeNameField {
    margin-right: 0px;
}

@media only screen and (max-width: 800px) {
    .fileInputContainer {
        margin-bottom: 0px;
    }

    .fileInputLabel {
        height: 100px;
        width: 150px;
    }
  }