.videoDetailWrapper {
    background-color: #000000;
    padding: 30px;
    min-height: 100vh;
}

.videoWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    height: 600px;
}

.mainVideo, .mainThumbnail {
    width: 80%;
    border: 1px solid goldenrod;
    max-height: 600px;
    cursor: pointer;
    padding: 15px;
}

@media only screen and (max-width: 800px) {
    .mainVideo, .mainThumbnail {
        width: 100%;
    }

    .videoWrapper {
        height: 400px;
    }
}
