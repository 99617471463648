.authDetailsWrapper {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.userName {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 25px;
    margin: 0px;
    color: goldenrod;
}

.signOutBtn {
    margin-top: 0px;
}

@media only screen and (max-width: 800px) {
    .authDetailsWrapper {
        gap: 15px;
    }
    
    .userName {
        font-size: 16px;
    }
}