.sectionWrapper {
    color: #ffffff; /* white text color */
    display: flex;
    flex-direction: column;
}

.sectionNameWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 450px;
    margin: 0 auto;
}

.videosWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.video {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.videoInputs {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.video input {
    width: 200px;
    margin-right: 15px;
}

.sectionWrapper button {
    margin: 0px;
}

.sectionWrapper label {
    color: #ffffff; /* white label text color */
}

.sectionWrapper input[type="checkbox"] {
    margin-right: 5px;
}

.sectionWrapper > div > span {
    color: #ffffff; /* white text color */
}

.sectionWrapper > div > input {
    margin: 0 10px;
    padding: 8px;
    border: 1px solid #ffffff; /* white border */
    background-color: #000000; /* black input background */
    color: #ffffff; /* white text color */
}

.sectionWrapper p {
    color: #ffffff; /* white text color */
}

/* You can customize these styles based on your design preferences */
.checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checkbox-label {
    margin-left: 8px;
    font-size: 16px; /* Adjust the font size as needed */
  }

  .checkbox-input {
    /* Optional: You can hide the default checkbox */
    display: none;
  }

  .addVideoWrapper {
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  .custom-checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid #333;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .custom-checkbox.checked {
    background-color: #4caf50; /* Adjust the color for the checked state */
    border-color: #4caf50;
  }

  .custom-checkbox.checked::after {
    content: '\2713'; /* Unicode checkmark character */
    color: #fff; /* Adjust the color for the checkmark */
    font-size: 14px; /* Adjust the font size of the checkmark */
  }

  .editName {
    margin-right: 0px;
  }

  @media only screen and (max-width: 800px) {
    .sectionNameWrapper {
      flex-direction: column;
      width: 200px;
      gap: 20px;
    }

    .saveNameBtn {
      width: 100%;
    }

    .video {
      flex-direction: column;
      gap: 10px;
    }
}
