.video-gallery {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
}

.videosListWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; /* Change from 'start' to 'flex-start' */
    width: 100%;
    gap: 50px;
    flex-wrap: wrap;
}

.videosListTitle {
    width: 100%;
    text-align: start;
    text-decoration: underline;
    margin-bottom: 0px;
}

.video-container {
    margin-bottom: 20px;
    text-align: start;
}

.responsive-video {
    width: 100%;
    height: auto;
}

.video-thumbnail {
    max-width: 400px;
    max-height: 350px;
    object-fit: cover;
    border: 2px solid goldenrod;
    cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .videosListWrapper {
        width: 100%;
        gap: 0px;
    }

    .video-thumbnail {
        width: 100%;
    }

    .video-container {
        width: 100%;
        text-align: center;
    }

    .videosListTitle {
        text-align: center;
    }
}