.nav {
    background-color: #000000;
    padding: 5px 20px;
    text-align: start;
    border-bottom: 3px solid goldenrod;
}

.nav-list {
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #ffffff;
    list-style-type: none;
    align-items: center;
}

.logo {
    height: 100px;
}

@media only screen and (max-width: 800px) {
    .nav {
        padding: 10px;
    }
    .nav-list {
        padding: 0px;
    }
    .logo {
        height: 50px;
    }
}
