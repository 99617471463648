/* Sections.module.css */
  .addSectionForm {
    display: flex;
    justify-content: center;
    margin: 25px 0px 50px;
  }

  .inputNameField {
    padding: 14px;
  }

  .sectionList {
    list-style-type: none;
    padding: 0;
    width: 33%;
    min-width: 300px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 50px;
  }

  .sectionItem {
    cursor: pointer;
    background-color: #f3f3f3;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    height: 50px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid goldenrod;
    position: relative;
  }

  .sectionItem:hover {
    background-color: goldenrod;
    font-weight: bold;
    border: 1px solid #ffffff;
  }

  .sectionItem button {
    margin-top: 0px;
    position: absolute;
    right: 10px;
  }

  .addSection {
    margin-top: 0px;
  }

  .addSection:disabled {
    background-color: lightgray;
    cursor: auto;
  }

  @media only screen and (max-width: 800px) {
    .addSectionForm {
      flex-direction: column;
      padding: 20px;
      gap: 15px;
      margin: 25px 0px;
    }

    .inputNameField {
      width: 275px;
      padding: 10px;
    }

    .sectionItem {
      height: 35px;
    }

    .sectionItem button {
      padding: 8px;
    }
  }