.goBackWrapper {
    margin-bottom: 30px;
    text-align: start;
    margin-left: 30px;
}

.goBack {
    color: goldenrod;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 15px;
    width: fit-content;
}

.goBack:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 800px) {
    .goBackWrapper {
        margin-left: 0px;
        font-size: 14px;
    }
}