.commentsWrapper {
    max-width: 400px;
    margin: auto;
}

.comments {
    color: #ffffff;
    margin-bottom: 30px;
}

.addCommentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 80%;
    margin: auto;
}

.addCommentTrxtArea {
    text-align: center;
    padding: 15px;
}

.addCommentButtonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
}

.commentBy {
    margin-right: 15px;
}

@media only screen and (max-width: 800px) {
    .comments {
        font-size: 12px;
    }
}