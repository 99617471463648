.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    color: white;
    padding: 30px;
    gap: 30px;
    height: 100%;
    min-height: 100vh;
  }

  .loading {
    font-size: 1.5rem;
    margin-top: 20px;
  }

  .mainBtn {
    background-color: goldenrod;
    color: black;
    padding: 15px;
    margin-top: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }

  .mainBtn:disabled {
    background-color: lightgray;
    cursor: default;
    color: gray;
  }

  .mainBtn:hover:not([disabled]) {
    background-color: #ffd700; /* Darker shade of gold on hover */
  }

  .deleteBtn {
    background-color: #ff3737;
    color: black;
    padding: 15px;
    margin-top: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }

  .deleteBtn:hover {
    background-color: #ff6c6c;
  }

  .generalBtn {
    background-color: #b7b7b7;
    color: black;
    padding: 15px;
    margin-top: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }

  .generalBtn:hover {
    background-color: lightgray
  }

  .container {
    padding: 20px;
    background-color: #000000;
    min-height: 100vh;
  }

  .containerContext {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 30px;
    color: #ffffff;
  }

  .formInput {
    padding: 8px;
    border: 1px solid #ffffff;
    background-color: #000000;
    color: #ffffff;
  }

  .formInput::placeholder {
    color: #ffffff;
  }

  .inputField {
    padding: 10px;
    margin-right: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 250px;
    background-color: #000000;
    color: #ffffff;
  }

  .inputField::placeholder {
    color: rgb(165, 165, 165);
  }

  input {
    outline-color: goldenrod;
  }

  textarea {
    outline-color: goldenrod;
    resize: none;
    border-radius: 5px;
  }

  .adminButtonsWrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .containerContextTitle {
    margin-bottom: 30px;
  }

  h1, h2, h3 {
    color: #ffd700;
  }

  @media only screen and (max-width: 800px) {
    .mainBtn, .deleteBtn, .generalBtn {
        font-size: 10px;
        padding: 12px 15px;
    }

    .containerContext {
      width: 100%;
      margin: 0px;
    }

    .containerContextTitle {
      font-size: 18px;
    }

    .containerContextSubTitle {
      font-size: 16px;
    }

    .inputField {
      width: 175px;
      text-align: center;
      margin-right: 0px;
      font-size: 12px;
    }

    h1 {
      font-size: 20px;
    }

    h2 {
      font-size: 18px;
    }

    h3 {
      font-size: 16px;
    }
}