.adminPageWrapper {
    padding: 30px;
    background-color: black;
    color: goldenrod;
    height: 100%;
    min-height: 100vh;
}

.usersListTitle {
    text-align: center;
}

.usersListWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 10px;
    grid-row-gap: 50px;
    padding: 30px;
    max-width: 1500px;
    margin: 30px auto;
  }

  @media screen and (max-width: 1400px) {
    .usersListWrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 1080px) {
    .usersListWrapper {
      grid-template-columns: repeat(1, 1fr);
    }
  }

.user-card {
    padding: 10px;
    margin: auto;
    border-radius: 5px;
    border: 3px solid goldenrod;
    width: 400px;
    background-color: black;
    color: white;
    font-size: 18px;
    height: 100%;
}

.userButtonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
}

@media only screen and (max-width: 800px) {
    .user-card {
        width: 100%;
        padding: 0px;
    }
}